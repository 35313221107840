import React, { useEffect, useReducer } from 'react';
import AuthContext from './AuthContext';
import { authReducer } from './AuthReducer';
import AuthRepositoryImpl from './AuthRepositoryImpl';
import { AuthActionType } from './AuthAction';
import { useLazyApi } from '../../core/functional/UseLazyApi';
import { User } from './User';
import { AuthData } from './AuthData';
import { SignupPayload } from '../../core/models/SignupPayload';
import { AuthState } from './AuthState';
import { useLocation } from 'react-router-dom';
import queryString from 'query-string';
import { environment } from '../../environment/environment';
import ProjectRepositoryImpl from '../../repositories/ProjectRepository/ProjectRepositoryImpl';

const AuthProvider = (props: {
  children: React.ReactNode;
}) => {
  const storageState = sessionStorage.getItem('auth');

  const parsedStorageState: AuthState = {
    ...storageState ? JSON.parse(storageState) : {},
    isLoading: false,
    isFirstLoading: true,
    isOnboardingCompleted: false,
    isUserLoading: true,
    isUpdateLocalUserLoading: true,
    isLoggingOut: false
  };

  const [state, dispatch] = useReducer(authReducer, parsedStorageState);
  const { fetch: getProfile, data: profile } = useLazyApi<User>();
  const { fetch: signup, data: signupData } = useLazyApi<AuthData>();
  const { fetch: completeLogin, fulfilledTimestamp: completeLoginDone, error: completeLoginError } = useLazyApi<void>();
  const { fetch: logout } = useLazyApi<void>();
  const { fetch: updateLocalUser, fulfilledTimestamp: updateLocalUserDone } = useLazyApi<void>();
  const location = useLocation();

  useEffect(() => {
    sessionStorage.setItem('auth', JSON.stringify(state));
  }, [state]);

  useEffect(() => {
    const { authCode } = queryString.parse(location.search);

    if (authCode && !Array.isArray(authCode)) {
      const authData: Optional<AuthData> = JSON.parse(window.atob(authCode));
      if (authData) {
        location.search = '';
        dispatch({ type: AuthActionType.SET_AUTH_DATA, payload: { user: authData } });
        dispatch({ type: AuthActionType.SET_CREATED_SOFT_QUOTE, payload: { hasCreatedSoftQuote: true } });
      }
    } else {
      const token = state.authData?.token;

      if (token) {
        completeLogin(() => AuthRepositoryImpl.completeLogin(token));
      } else {
        logout(() => AuthRepositoryImpl.logout());

        if (state.user) {
          dispatch({ type: AuthActionType.DELETE_USER });
        }

        if (state.isUpdateLocalUserLoading) {
          dispatch({ type: AuthActionType.SET_UPDATE_LOCAL_USER_LOADING, payload: { loading: false } });
        }

        if (state.isUserLoading) {
          dispatch({ type: AuthActionType.SET_USER_LOADING, payload: { loading: false } });
        }
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [state]);

  useEffect(() => {
    updateLocalUser(() => AuthRepositoryImpl.updateLocalUser());
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [completeLoginDone]);

  useEffect(() => {
    if (updateLocalUserDone && state.isUpdateLocalUserLoading) {
      dispatch({ type: AuthActionType.SET_UPDATE_LOCAL_USER_LOADING, payload: { loading: false } });
    }

    if (updateLocalUserDone && state.isLoading) {
      dispatch({ type: AuthActionType.SET_LOADING, payload: { loading: false } });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [updateLocalUserDone]);

  useEffect(() => {
    if (completeLoginError) {
      if(process.env.REACT_APP_MODE !== 'production') console.log(completeLoginError);
      dispatch({ type: AuthActionType.DELETE_USER });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [completeLoginError]);

  useEffect(() => {
    const userData = state.authData;
    if (userData) {
      getProfile(() => AuthRepositoryImpl.getUser(userData.oldResponse.token));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [state.authData]);

  useEffect(() => {
    if (signupData) {
      dispatch({ type: AuthActionType.SET_AUTH_DATA, payload: { user: signupData } });
    }
  }, [signupData]);

  useEffect(() => {
    if (profile) {
      dispatch({ type: AuthActionType.SET_PROFILE, payload: { profile } });
      dispatch({ type: AuthActionType.SET_USER_LOADING, payload: { loading: false } });
    }
  }, [profile]);

  const provided: React.ContextType<typeof AuthContext> = {
    state,
    dispatch,
    signup: async (data: SignupPayload) => {
      dispatch({ type: AuthActionType.SET_LOADING, payload: { loading: true } });
      return signup(() => AuthRepositoryImpl.signup(data));
    },
    signout: () => {
      dispatch({ type: AuthActionType.SET_LOGGING_OUT, payload: { loggingOut: true } });
      dispatch({ type: AuthActionType.DELETE_USER });
      window.open(`${environment.AUTH_APP_URL}?logout=true`, '_self');
    }
  };

  return (
    <AuthContext.Provider value={provided}>
      {props.children}
    </AuthContext.Provider>
  );
};

export default AuthProvider;

