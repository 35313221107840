import React from 'react';
import ReactDOM from 'react-dom';
import App from './App';
import reportWebVitals from './reportWebVitals';
import './index.css';
import './assets/styles/fonts.css';
import { BrowserRouter } from 'react-router-dom';
import AuthRepositoryImpl from './features/auth/AuthRepositoryImpl';
import AuthProvider from './features/auth/AuthProvider';
import { BrowserTracing } from '@sentry/tracing';
import * as Sentry from '@sentry/react';
import { environment } from './environment/environment';
// @ts-ignore
import { hotjar } from 'react-hotjar';
import smoothscroll from 'smoothscroll-polyfill';

AuthRepositoryImpl.init();

Sentry.init({
  dsn: 'https://9e30fa1debe447a0be3eea303153409b@o1230031.ingest.sentry.io/6393915',
  integrations: [new BrowserTracing()],

  // Set tracesSampleRate to 1.0 to capture 100%
  // of transactions for performance monitoring.
  // We recommend adjusting this value in production
  tracesSampleRate: 1.0,
  environment: environment.SENTRY_ENV_NAME
});

hotjar.initialize(2969466, 6);

smoothscroll.polyfill();

ReactDOM.render(
  <React.StrictMode>
    <BrowserRouter>
      <AuthProvider>
        <App />
      </AuthProvider>
    </BrowserRouter>
  </React.StrictMode>,
  document.getElementById('root')
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals(undefined);

console.debug(`App run in mode: ${process.env.REACT_APP_MODE ?? 'production'}`);
