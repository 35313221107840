import { getModelMapper } from '../../core/functional/ModelMapper';

export type ServerAuthData = {
  data: {
    oldResponse: {
      token: string;
    };
    token: string;
  };
};

export type AuthData = {
  oldResponse: {
    token: string;
  };
  token: string;
};

export const AuthDataMapper = getModelMapper<AuthData, ServerAuthData>(
  model => model.data,
  model => ({ data: model })
);
