import axiosClient from 'axios';
import {toast} from 'react-toastify';
import queryString from 'query-string';

export const axiosV1 = axiosClient.create();
export const axiosV2 = axiosClient.create();

let requests = 0;

const handleCallEnd = () => {
  requests--;
  if (requests <= 0) {
    requests = 0;
    // hide loader
  }
};

axiosV2.interceptors.request.use(config => {
  requests++;
  const { fbclid } = queryString.parse(window.location.search);
  if (fbclid && config.headers) {
    config.headers.ia_fbclick = fbclid.toString();
  }
  return config;
}, error => {
  handleCallEnd();
  return Promise.reject(error);
});

axiosV2.interceptors.response.use(response => {
  handleCallEnd();
  return response;
}, error => {
  handleCallEnd();
  toast.error(error.response?.data?.message ?? error.message, {theme: 'colored'});
  return Promise.reject(error);
});
