import {Navigate, Route, Routes} from 'react-router-dom';
import { RoutePath } from '../../core/models/RoutePath';
import React from 'react';
import queryString from 'query-string';
import { AuthAction } from './AuthAction';
import { SignupPayload } from '../../core/models/SignupPayload';
import { AuthData } from './AuthData';
import { User } from './User';

const ThankYou = React.lazy(() => import('../../containers/ThankYou/ThankYou'));
const ProfilingForm = React.lazy(() => import('../../containers/ProfilingForm/ProfilingForm'));
const Onboarding = React.lazy(() => import('../../containers/Onboarding/Onboarding'));

type AuthRouterProps = {
  hasCreatedSoftQuote: boolean,
  isOnboardingCompleted: boolean,
  isLoggedIn: boolean,
  queryParams?: any,
  authData?: AuthData,
  user?: User,
  dispatch: React.Dispatch<AuthAction>,
  isLoading: boolean,
  signup: (data: SignupPayload) => void,
  navigate: any,
  location: any
}
const AuthRouter = (props: AuthRouterProps) => {
  
  const { isLoggedIn, hasCreatedSoftQuote, isOnboardingCompleted, queryParams, authData, user, dispatch,isLoading, signup, location, navigate } = props;
  
  const routes = isLoggedIn && hasCreatedSoftQuote
  ? (
    <div className='container p-5 mx-auto'>
      <Routes>
        <Route path={RoutePath.PERSONAL_INFORMATION} element={<ProfilingForm />} />
        <Route path={RoutePath.ANY} element={<Navigate to={queryString.stringifyUrl({
          url: RoutePath.PERSONAL_INFORMATION,
          query: queryParams
        })} />} />
      </Routes>
    </div>
  )
  : (
    isOnboardingCompleted ?
      <Routes>
        <Route path={RoutePath.THANK_YOU} element={<ThankYou />} />
        <Route path={RoutePath.ANY} element={<Navigate to={queryString.stringifyUrl({
          url: RoutePath.THANK_YOU,
          query: queryParams
        })} />} />
      </Routes>
    :
      <Onboarding 
        isLoggedIn={isLoggedIn}
        authData={authData}
        user={user}
        dispatch={dispatch}
        isLoading={isLoading}
        signup={signup}
        location={location}
        navigate={navigate}
      />
  );

  return (
    <>
      {routes}
    </>
  );
};

export default AuthRouter;
