/**
 * This function allows you to quickly setup map utilities for a model
 * @template T - The output model of the mapper
 * @template S - The input model for the mapper
 * @param map - map function
 * @param reverseMap - reverse map function
 */
export const getModelMapper = <T, S>(map: Map<T, S>, reverseMap: ReverseMap<T, S>): ModelMapper<T, S> =>
    ({
        map,
        reverseMap,
        mapArray: models => models.map(map),
        reverseMapArray: models => models.map(reverseMap)
    });

export type ModelMapper<T, S> = {
    map: Map<T, S>;
    reverseMap: ReverseMap<T, S>;
    mapArray: MapArray<T, S>;
    reverseMapArray: ReverseMapArray<T, S>;
};

export type Map<T, S> = (model: S) => T;
export type ReverseMap<T, S> = (model: T) => S;
export type MapArray<T, S> = (models: S[]) => T[];
export type ReverseMapArray<T, S> = (models: T[]) => S[];
