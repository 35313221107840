import React from 'react';
import { AuthState } from './AuthState';
import { authInitialState } from './AuthReducer';
import { AuthAction } from './AuthAction';
import { SignupPayload } from '../../core/models/SignupPayload';

const AuthContext = React.createContext<{
  state: AuthState;
  dispatch: React.Dispatch<AuthAction>;
  signup: (data: SignupPayload) => void;
  signout: () => void;
}>({
  state: authInitialState,
  dispatch: () => void 0,
  signup: () => void 0,
  signout: () => void 0
});

export default AuthContext;
