import { useContext } from 'react';
import AuthContext from '../../features/auth/AuthContext';

export const useAuth = () => {
  const { state, ...rest } = useContext(AuthContext);

  return {
    ...state,
    ...rest,
    isLoggedIn: !!(state.authData && state.user),
    isFirstLoading: state.isUserLoading || state.isUpdateLocalUserLoading
  };
};
