import { Reducer } from 'react';
import { AuthAction, AuthActionType } from './AuthAction';
import { AuthState } from './AuthState';

export const authInitialState: AuthState = {
  isLoading: false,
  isFirstLoading: true,
  hasCreatedSoftQuote: false,
  isOnboardingCompleted: false,
  isUpdateLocalUserLoading: false,
  isUserLoading: false,
  isLoggingOut: false
};

export const authReducer: Reducer<AuthState, AuthAction> = (state, action) => {
  switch (action.type) {
    case AuthActionType.SET_PROFILE:
      return {
        ...state,
        user: action.payload.profile
      };
    case AuthActionType.SET_SIGNUP_DATA:
      return {
        ...state,
        signupData: action.payload.signupData
    };
    case AuthActionType.DELETE_USER:
      return {
        ...state,
        user: undefined,
        authData: undefined,
        hasCreatedSoftQuote: false
      };
    case AuthActionType.SET_AUTH_DATA:
      return {
        ...state,
        authData: action.payload.user
      };
    case AuthActionType.SET_LOADING:
      return {
        ...state,
        isLoading: action.payload.loading
      };
    case AuthActionType.SET_FIRST_LOADING:
      return {
        ...state,
        isFirstLoading: action.payload.loading
      };
    case AuthActionType.SET_CREATED_SOFT_QUOTE:
      return {
        ...state,
        hasCreatedSoftQuote: action.payload.hasCreatedSoftQuote
      };
    case AuthActionType.SET_ONBOARDING_COMPLETED:
      return {
        ...state,
        isOnboardingCompleted: action.payload.completed
      };
    case AuthActionType.SET_USER_LOADING:
      return {
        ...state,
        isUserLoading: action.payload.loading
      };
    case AuthActionType.SET_UPDATE_LOCAL_USER_LOADING:
      return {
        ...state,
        isUpdateLocalUserLoading: action.payload.loading
      };
    case AuthActionType.SET_LOGGING_OUT:
      return {
        ...state,
        isLoggingOut: action.payload.loggingOut
      };
  }
};
