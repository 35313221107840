import logoWhite from '../../assets/images/logo_ia_white.png';
import { environment } from '../../environment/environment';
import './Footer.scss';
import {useLocation} from 'react-router-dom';

const Footer = () => {
  
  const location = useLocation();

  return (
    <footer id='footer'>
      <div className='main bg-primary'>
        <div className='container px-10'>
          <div className='logo'>
            <a href={environment.WEBSITE_BASE_URL + location.search}>
              <img 
                alt='italian artisan logo'
                src={logoWhite} 
              />
            </a>
          </div>

          
          <div className='menu'>
            <p className='mb-3 font-bold'>Support</p>

            <a href={`https://italianartisan.com/contact/${location.search}`} className='mb-0'>
              Contact us 
            </a>
            <a
              href={`https://italianartisan.com/faq/${location.search}`}
              className='mb-0'
            >
              FAQ
            </a>
            <a
              href={`https://italianartisan.com/how-we-work/${location.search}`}
              className='mb-0'
            >
              How it Works
            </a>
          </div>
          <div className='menu'>
            <p className='mb-3 font-bold'>Social</p>
            <a
              href='https://www.facebook.com/italianartisan/'
              className='mb-0'
              target='_blank'
            >
              Facebook
            </a>
            <a
              href='https://www.instagram.com/italian_artisan/'
              className='mb-0'
              target='_blank'
            >
              Instagram
            </a>
            <a
              href='https://www.youtube.com/channel/UCXbBexKdM41bDX8Vjtqu-Lg'
              className='mb-0'
              target='_blank'
            >
              Youtube
            </a>
            <a
              href='https://it.linkedin.com/company/italian-artisan'
              className='mb-0'
              target='_blank'
            >
              LinkedIn
            </a>
          </div>
        </div>
      </div>
      <div className='copy'>
        <div className='container px-10'>
          <a>Copyright © {new Date().getFullYear()} Italian Artisan</a>
          <a>Artisan DNA Srl | P.IVA: 01866390436</a>
          <a
            href={`https://italianartisan.com/privacy-policy/${location.search}`}
            target='_blank'
          >
            Privacy Policy
          </a>
          <a
            href={`https://italianartisan.com/terms-and-condition/${location.search}`}
            target='_blank'
          >
            Terms and conditions
          </a>
        </div>
      </div>
    </footer>
  );
};

export default Footer;
