interface Environment {
  API_BASE_URL_V1: string;
  API_BASE_URL_V2: string;
  API_BASE_URL_V3: string;
  API_BASE_URL_CATEGORIES: string;
  API_BASE_URL_ONBOARDING: string;
  WEBSITE_BASE_URL: string;
  WEBAPP_BASE_URL: string;
  THANK_YOU_PAGE: string;
  AUTH_APP_URL: string;
  FIREBASE_CONFIG: Record<string, string>;
  SENTRY_ENV_NAME: string;
}

const environments: { [key: string]: Environment } = {
  development: {
    API_BASE_URL_V1: 'https://italian-artisan-server.onrender.com/api/v1/',
    API_BASE_URL_V2: 'https://api-italian-artisan.onrender.com/v1/',
    API_BASE_URL_V3: 'https://api-italian-artisan.onrender.com/v2/',
    API_BASE_URL_CATEGORIES: 'https://categories-service.onrender.com/',
    API_BASE_URL_ONBOARDING: 'https://onboarding-backend.onrender.com/api/',
    WEBSITE_BASE_URL: 'https://italian-artisan.com/',
    WEBAPP_BASE_URL: 'https://italian-artisan-webapp.onrender.com/',
    THANK_YOU_PAGE: 'https://italian-artisan.com/custom-signup-thank-you-page/',
    AUTH_APP_URL: 'https://auth-italian-artisan-stage.web.app/',
    FIREBASE_CONFIG: {
      apiKey: 'AIzaSyCItzWhbPfswgUHBJLy7KcPxsACsJ4PNrk',
      authDomain: 'italian-artisan-stage-898c0.firebaseapp.com',
      databaseURL: 'https://italian-artisan-stage-898c0.firebaseio.com',
      projectId: 'italian-artisan-stage-898c0',
      storageBucket: 'italian-artisan-stage-898c0.appspot.com',
      messagingSenderId: '1002429327218',
      appId: '1:1002429327218:web:cbb8a47f7baefaf0d35feb'
    },
    SENTRY_ENV_NAME: 'development'
  },
  production: {
    API_BASE_URL_V1: 'https://italian-artisan.herokuapp.com/api/v1/',
    API_BASE_URL_V2: 'https://api-italian-artisan.herokuapp.com/v1/',
    API_BASE_URL_V3: 'https://api-italian-artisan.herokuapp.com/v2/',
    API_BASE_URL_CATEGORIES: 'https://categories-service.herokuapp.com/',
    API_BASE_URL_ONBOARDING: 'https://ia-onboarding-backend.herokuapp.com/api/',
    WEBSITE_BASE_URL: 'https://italian-artisan.com/',
    WEBAPP_BASE_URL: 'https://app.italian-artisan.com/',
    THANK_YOU_PAGE: 'https://italian-artisan.com/custom-signup-thank-you-page/',
    AUTH_APP_URL: 'https://account.italian-artisan.com/',
    FIREBASE_CONFIG: {
      apiKey: 'AIzaSyA3oDvnoboPjyXtomo2eoI6JYJPJ0oGIY4',
      authDomain: 'italian-artisan.firebaseapp.com',
      databaseURL: 'https://italian-artisan.firebaseio.com',
      projectId: 'italian-artisan',
      storageBucket: 'italian-artisan.appspot.com',
      messagingSenderId: '740092147048',
      appId: '1:740092147048:web:70f8f2ba41d5bee1b312dd'
    },
    SENTRY_ENV_NAME: 'production'
  }
};

export const environment = environments[process.env.REACT_APP_MODE ?? 'production'];
