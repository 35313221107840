import React, { useEffect } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import Footer from './components/Footer/Footer';
import AuthRouter from './features/auth/AuthRouter';
import Navbar from './components/Navbar/Navbar';
import { useAuth } from './core/functional/UseAuth';
import queryString from 'query-string';
import { CgSpinner } from 'react-icons/cg';
import { GrowthBook } from '@growthbook/growthbook-react';
import { GrowthBookProvider } from '@growthbook/growthbook-react';


function App() {
  const { user, dispatch, isLoading, isLoggedIn, signup, signout, authData, isOnboardingCompleted, isFirstLoading, isLoggingOut, hasCreatedSoftQuote } = useAuth();

  const location = useLocation();
  const navigate = useNavigate();

  const params = queryString.parse(location.search);

  const growthbook = new GrowthBook({
    apiHost: 'https://cdn.growthbook.io',
    clientKey: 'sdk-TxNLEXd6Cmumbd',
    enableDevMode: false,
    trackingCallback: (experiment, result) => {
      console.log('Viewed Experiment', {
        experimentId: experiment.key,
        variationId: result.key
      });
    }
  });



  useEffect(() => {
    growthbook.init({ streaming: true });

  }, [location.pathname]);

  const spinnerLoader: JSX.Element = (
    <div className='h-screen grid place-content-center'>
      <CgSpinner className='animate-spin w-10 h-10 mx-auto' />
    </div>
  );

  return (
    <GrowthBookProvider growthbook={growthbook}>

      <React.Suspense fallback={spinnerLoader}>

        {(isFirstLoading || isLoggingOut) && spinnerLoader}

        {!isFirstLoading && !isLoggingOut &&
          <div className='App'>
            <Navbar
              location={location}
              navigate={navigate}
              isSigned={isLoggedIn}
              onSignout={signout}
            />

            <div className='pt-16 mb-10 min-h-screen'>
              <AuthRouter
                queryParams={params}
                isLoggedIn={isLoggedIn}
                hasCreatedSoftQuote={hasCreatedSoftQuote}
                isOnboardingCompleted={isOnboardingCompleted}
                authData={authData}
                signup={signup}
                user={user}
                dispatch={dispatch}
                isLoading={isLoading}
                navigate={navigate}
                location={location}

              />
            </div>

            <Footer />
          </div>
        }
      </React.Suspense>


    </GrowthBookProvider>

  );
}

export default App;
