export enum RoutePath {
  ANY = '*',
  DEFAULT = '/',
  NEW_PROJECT = '/soft-quote',
  SIGN_UP = '/sign-up',
  THANK_YOU = '/thank-you',
  DASHBOARD = '/dashboard',
  PERSONAL_INFORMATION = '/personal-information',
  LANDING = '/lp-custom'
}
