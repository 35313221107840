import { asyncCaseOf, Either } from './Either';
import { useState } from 'react';
import { DateTime } from 'luxon';

export const useLazyApi = <T>(
  options?: {}
) => {
  const [isLoading, setLoading] = useState(false);
  const [data, setData] = useState<T>();
  const [error, setError] = useState<Error>();
  const [isSuccess, setSuccess] = useState(false);
  const [successTimestamp, setSuccessTimestamp] = useState<number>();
  const [fulfilledTimestamp, setFulfilledTimestamp] = useState<number>();

  const makeCall = async (call: () => Promise<Either<T>>) => {
    setLoading(true);

    await asyncCaseOf({
      Left: err => {
        setError(err);
      },
      Right: v => {
        setSuccess(true);
        setSuccessTimestamp(DateTime.now().toMillis());
        setData(v);
      }
    }, call());

    setFulfilledTimestamp(DateTime.now().toMillis());
    setLoading(false);
  };

  return {
    isLoading,
    data,
    error,
    isSuccess,
    successTimestamp,
    fulfilledTimestamp,
    fetch: (fetch: () => Promise<Either<T>>) => makeCall(fetch)
  };
};
