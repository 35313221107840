import { SignupPayload } from '../../core/models/SignupPayload';
import { LoginPayload } from '../../core/models/LoginPayload';
import { environment } from '../../environment/environment';
import { axiosV1, axiosV2 } from '../../core/api/Axios';
import { AuthRepository } from './AuthRepository';
import { AuthData, AuthDataMapper, ServerAuthData } from './AuthData';
import { ApiResult } from '../../core/models/ApiResponse';
import { ServerUser, User } from './User';
import firebase from 'firebase';
import { asyncTryCatch, right } from '../../core/functional/Either';

const AuthRepositoryImpl: AuthRepository = {
  async signup(payload: SignupPayload) {
    return asyncTryCatch(async () => {
      const body = {...payload, subscription_platform: 'custom'};
      const { data: authData } = await axiosV2.post<{ data: AuthData; }>(`${environment.API_BASE_URL_V2}auth/brand/register-and-complete`, body);
      return AuthDataMapper.map(authData);
    }, e => {
      if (e instanceof Error) {
        return e;
      } else {
        return new Error();
      }
    });
  },

  async login(payload: LoginPayload): Promise<ApiResult<AuthData>> {
    return asyncTryCatch(async () => {
      const { data } = await axiosV2.post<ServerAuthData>(`${environment.API_BASE_URL_V2}auth/login`, payload);
      return AuthDataMapper.map(data);
    }, e => {
      if (e instanceof Error) {
        return e;
      } else {
        return new Error();
      }
    });
  },

  async completeLogin(token) {
    return asyncTryCatch(async () => {
      await firebase.auth().signInWithCustomToken(token);
    }, e => {
      if (e instanceof Error) {
        return e;
      } else {
        return new Error();
      }
    });
  },

  async getUser(token): Promise<ApiResult<User>> {
    return asyncTryCatch(async () => {
      const { data } = await axiosV1.get<ServerUser>(`${environment.API_BASE_URL_V1}me/`, {
        headers: {
          'Authorization': `Token ${token}`
        }
      });
      return data;
    }, e => {
      if (e instanceof Error) {
        return e;
      } else {
        return new Error();
      }
    });
  },

  setApiAuthorization(token?: string) {
    if (token) {
      axiosV2.defaults.headers.common['ia_auth_token'] = `${token}`;
    } else {
      delete axiosV2.defaults.headers.common['ia_auth_token'];
    }
  },

  init(): void {
    firebase.initializeApp(environment.FIREBASE_CONFIG);
  },

  isLoggedIn(): boolean {
    return !!firebase.auth().currentUser;
  },

  listenUser(callback): () => void {
    return firebase.auth().onAuthStateChanged(user => callback(!!user));
  },

  async logout(): Promise<ApiResult<void>> {
    return asyncTryCatch(async () => {
      return firebase.auth().signOut();
    }, e => {
      if (e instanceof Error) {
        return e;
      } else {
        return new Error();
      }
    });
  },

  async updateLocalUser() {
    return asyncTryCatch(async () => {
      const token = await firebase.auth().currentUser?.getIdToken();
      AuthRepositoryImpl.setApiAuthorization(token);
    }, e => {
      if (e instanceof Error) {
        return e;
      } else {
        return new Error();
      }
    });
  },

  async getAuthToken(): Promise<ApiResult<Optional<string>>> {
    return right(await firebase.auth().currentUser?.getIdToken());
  }
};

export default AuthRepositoryImpl;
