import logoBlack from '../../assets/images/logo_ia_black.png';
import { FaRegUserCircle } from 'react-icons/fa';
import locale from '../../core/api/Locale';
import { NavigateFunction } from 'react-router-dom';
import { RoutePath } from '../../core/models/RoutePath';

type NavbarProps = {
  isSigned?: boolean,
  onSignout?: () => void,
  location: any,
  navigate: NavigateFunction
}
const Navbar = (props: NavbarProps) => {

  const { isSigned, onSignout, location, navigate } = props;

  const onCallToActionClick = (name: string) => {
    navigate({
      pathname: RoutePath.NEW_PROJECT,
      search: location.search
    });
  };

  return (
    <div id='#onboarding-navbar' className='navbar bg-white shadow-md fixed z-20'>
      <div>
          <img alt='italian artisan logo' src={logoBlack} className='h-8' />
      </div>

      {isSigned
        && (
          <div className='dropdown dropdown-end ml-auto mr-1'>
            <button className='btn btn-ghost rounded-full aspect-square p-0'>
              <FaRegUserCircle
                size={28} />
            </button>
            <ul tabIndex={0} className='dropdown-content menu p-2 shadow bg-base-100 rounded-box w-52 z-10'>
              <li onClick={onSignout}><a>{locale.navbar.logout}</a></li>
            </ul>
          </div>
        )}

      {!isSigned && location.pathname === RoutePath.LANDING
        && (
          <button className='btn btn-primary my-2 btn-signup ml-auto'
                  onClick={() => onCallToActionClick('Get started - 1')}>
            Get started!
          </button>
        )}
    </div>
  );
};

export default Navbar;
