import { User } from './User';
import { AuthData } from './AuthData';
import { SignupPayload } from '../../core/models/SignupPayload';

export type AuthAction =
  | { type: AuthActionType.SET_PROFILE, payload: { profile: User } }
  | { type: AuthActionType.DELETE_USER }
  | { type: AuthActionType.SET_AUTH_DATA, payload: { user: AuthData } }
  | { type: AuthActionType.SET_LOADING, payload: { loading: boolean } }
  | { type: AuthActionType.SET_FIRST_LOADING, payload: { loading: boolean } }
  | { type: AuthActionType.SET_CREATED_SOFT_QUOTE, payload: { hasCreatedSoftQuote: boolean; } }
  | { type: AuthActionType.SET_ONBOARDING_COMPLETED, payload: { completed: boolean; } }
  | { type: AuthActionType.SET_USER_LOADING, payload: { loading: boolean; } }
  | { type: AuthActionType.SET_UPDATE_LOCAL_USER_LOADING, payload: { loading: boolean; } }
  | { type: AuthActionType.SET_LOGGING_OUT, payload: { loggingOut: boolean; } }
  | { type: AuthActionType.SET_SIGNUP_DATA, payload: { signupData: SignupPayload; } };

export enum AuthActionType {
  SET_AUTH_DATA = 'set_user',
  SET_PROFILE = 'set_profile',
  DELETE_USER = 'delete_user',
  SET_LOADING = 'set_loading',
  SET_FIRST_LOADING = 'set_first_loading',
  SET_CREATED_SOFT_QUOTE = 'set_created_soft_quote',
  SET_ONBOARDING_COMPLETED = 'set_onboarding_completed',
  SET_UPDATE_LOCAL_USER_LOADING = 'set_update_local_user_loading',
  SET_USER_LOADING = 'set_user_loading',
  SET_LOGGING_OUT = 'set_logging_out',
  SET_SIGNUP_DATA = 'set_signup_data'
}
